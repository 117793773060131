<template>
  <!--员工管理-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <el-row :gutter="20" style="margin-top: 15px;">
        <el-col :span="12">
          <el-card shadow="never">
            <div>
              <el-button
                @click="Add()"
                type="primary"
                icon="el-icon-plus"
                class="ele-btn-icon"
                size="small">添加
              </el-button>
              <el-button
                @click="Addxiaji()"
                plain
                type="primary"
                icon="el-icon-plus"
                class="ele-btn-icon"
                size="small">添加下级
              </el-button>
              <el-popconfirm title="确定删除吗？" @confirm=" remove(select)">
                <el-button
                  style="margin-left: 10px;"
                  slot="reference"
                  type="danger"
                  icon="el-icon-delete"
                  class="ele-btn-icon"
                  :disabled="!select"
                  size="small">删除
                </el-button>
              </el-popconfirm>
            </div>

            <div style="background: #fff6ea;border-radius: 5px;padding: 10px 20px 10px 20px;margin-top: 20px;display: flex;align-items: center;cursor: pointer;" v-if="weixuanzhong">
              <img src="@/assets/images/home/renli/biaozhi.png" alt="">
              <span style="font-size: 14px;font-weight: 400;color: #3f4157;margin-left: 10px;">末选中任何数据</span>
            </div>

            <el-row :gutter="15" style="margin-top: 20px;">
              <el-col :span="20">
                <el-input
                    placeholder="按部门名称搜索"
                    prefix-icon="el-icon-search"
                    v-model="name">
                </el-input>
              </el-col>
              <el-col :span="4">
                <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;" @click="query">查询</el-button>
              </el-col>
            </el-row>

            <div style="margin-top: 20px;">
              <el-tree
                  ref="tree"
                  :data="data"
                  show-checkbox
                  node-key="id"
                  :check-strictly="true"
                  @check-change="handleClick"
                  @check="treeCheck"
                  :props="defaultProps">
              </el-tree>
            </div>

          </el-card>
        </el-col>

        <el-col :span="12" v-if="show === true">
          <el-card shadow="never">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px">

              <div style="display: flex;align-items: center;">
                <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
                <span style="font-size: 20px;font-weight: 700;color: #202033;">基本信息</span>
              </div>

              <div style="margin-top: 15px;">
                <el-form-item label="机构名称" prop="name">
                  <el-input clearable v-model="form.name" placeholder="请输入机构名称"></el-input>
                </el-form-item>
                <el-form-item label="上级部门">
                  <el-select
                    clearable
                    v-model="form.pid"
                    placeholder="请选择"
                    class="ele-fluid">
                    <el-option
                      v-for="(item) in options"
                      :label="item.label"
                      :value="parseInt(item.id)"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="机构编码">
                  <el-input :disabled="true" clearable v-model="form.code" placeholder="请输入机构编码"></el-input>
                </el-form-item>
                <el-form-item label="机构类型">
                  <el-select
                    clearable
                    :disabled="true"
                    v-model="form.type"
                    placeholder="请选择"
                    class="ele-fluid">
                    <el-option
                      v-for="(item) in dict.type.company_type"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                  <el-input clearable v-model="form.sort" placeholder="请输入排序"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                  <el-input clearable v-model="form.phone" placeholder="请输入电话"></el-input>
                </el-form-item>
                <el-form-item label="传真" prop="faxes">
                  <el-input clearable v-model="form.faxes" placeholder="请输入传真"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="address">
                  <el-input clearable v-model="form.address" placeholder="请输入地址"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 5}"
                      placeholder="请输入内容"
                      v-model="form.note">
                  </el-input>
                </el-form-item>
              </div>

            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div style="margin-top: 30px;text-align: center;" v-if="show === true">
      <el-button style="width: 200px !important;" @click="cancel">取消</el-button>
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')">提交</el-button>
    </div>

  </div>
</template>

<script>
import {
  Adddepartments,
  Editdepartments,
  get_format,
  getdepartments,
  Detailsdepartments,
  Deldepartments,
} from "@/api/humanResources";
import Vue from "vue";

export default {
  // 组件生效
  components: {

  },
  data(){
    return{
      name:'',
      form:{},
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' },
        ],
        sort:[
          { required: true, message: '请输入排序', trigger: 'blur' },
        ],
        phone:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        faxes:[
          { required: true, message: '请输入传真', trigger: 'blur' },
        ],
        address:[
          { required: true, message: '请输入地址', trigger: 'blur' },
        ],
      },
      isUpdate:false,

      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },

      //字典
      dict: {
        type:{}
      },

      //格式化数据
      options:[],

      show:false,

      //选择的数据
      select:null,

      weixuanzhong:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '人力字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    //机构类型默认值
    this.form.type = '0';
    //机构编码默认值
    this.form.code = '123';

    // 获取列表
    this.getList();

    // 获取格式化接口
    this.getGeshihua();
  },

  methods:{
    // 获取列表
    getList(){
      let where = {
        name: this.name
      }
      getdepartments(where).then(res => {
        console.log(res);
        this.data = res.data.list;
      })
    },

    // 查询事件
    query(){
      this.getList();
    },

    //点击新增
    Add(){
      this.show = true;
    },
    //点击取消
    cancel(){
      this.show = false;
    },

    //点击添加下级
    Addxiaji(){
      console.log(this.select);
      if(Object.keys(this.select).length==0){
        this.weixuanzhong = true;
      }
      this.isUpdate = false;
      // 获取详情
      Detailsdepartments(this.select.id).then(res => {
        this.form = {};
        //机构类型默认值
        this.form.type = '0';
        //机构编码默认值
        this.form.code = '123';
        // 添加下级的父级id
        this.form.pid = res.data.id;
      })
    },

    //树选择
    handleClick(data,checked, node){
      if(checked){
        console.log(data);
        this.select = data;
        this.show = true;
        this.isUpdate = true;
        // 获取详情
        Detailsdepartments(this.select.id).then(res => {
          this.form = res.data;
        })
      }else {
        this.select = null;
        this.show = false;
        this.isUpdate = false;
      }
    },
    //实现单选
    treeCheck:function(node,list){
      //node 该节点所对应的对象、list 树目前的选中状态对象
      //选中事件在选中后执行，当lis中有两个选中时，使用setCheckedKeys方法，选中一个节点
      if (list.checkedKeys.length == 2) {
        //单选实现
        this.$refs.tree.setCheckedKeys([node.id]);
      }
    },


    //获取格式化后的部门接口
    getGeshihua(){
      get_format().then(res => {
        console.log(res);
        this.options = res.data;
      })
    },

    // 提交
    save(formName){
      console.log(this.form)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断是创建还是编辑
          if(this.isUpdate === false){
            if(this.form.pid == undefined){
              this.form.pid = 0;
            }
            //创建
            Adddepartments(this.form).then(res => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.form = {};
                this.select = null;
                this.isUpdate = false;
                this.show = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }else {
            //编辑
            Editdepartments(this.form.id,this.form).then(res => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.form = {};
                this.isUpdate = false;
                this.show = false;
                this.select = null;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },

    // 删除
    remove(select){
      Deldepartments(select.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.form = {};
          this.isUpdate = false;
          this.show = false;
          this.select = null;
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
